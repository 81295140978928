<template>
  <router-view />
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('sites', ['multigroups']),
    activeMultigroup() {
      return this.multigroups.find((e) => e.id === this.$route.params.app);
    },
  },
  mounted() {
    if (this.activeMultigroup) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: `${this.activeMultigroup.name} Sites`,
        to: { name: 'sites-listing', params: { app: this.activeMultigroup.id } },
        last: false,
      });
    } else {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: 'All Sites',
        to: { name: 'sites-listing', params: { app: 'all' } },
        last: false,
      });
    }
  },
};
</script>

<style></style>
